export default {
  title: "FAQs",
  text: "We will be updating this section regularly, so consider visiting our website time to time.",
  questions: {
    question_1: {
      title: "What’s an NFT?",
      text: "NFT stands for Non-fungible token, which means that it's a unique, digital item with blockchain-managed ownership that users can buy, own, and trade. Some NFT's fundamental function is to be digital art. But they can also offer additional benefits like exclusive access to websites, event tickets, game items, and ownership records for physical objects. Think of it as a unique piece of art that can also work as a members-only card. Shamanzs works like this.",
    },
    question_2: {
      title: "What’s Metamask and how can I use it to purchase Shamanzs?",
      text: "Metamask is a crypto wallet that can store your Ethereum, and is needed to purchase and mint a Shamanzs. Having a wallet gives you an Ethereum address (i.e. 0xABCD….1234), this is where your NFT will be stored. Learn more about Metamask and how easy it is to use over here! https://metamask.io/. In order to purchase your NFT , download the metamask.io extension for the Chrome browser or app on mobile. This will allow you to make purchases with Ethereum and can be found in the extensions tab. If you are on mobile, you must use the Metamask App Browser. You can purchase Ethereum through the Metamask Wallet using Wyre or Send Ethereum from an exchange like Coinbase. Click on Connect at the top of the page and connect your Metamask. Once joined, you will be able to purchase the NFTs in the mint section. You will be prompted to sign your transaction. FYI, there will be a fee associated with every transaction related to gas prices. Once you have made your purchase, your Shamanzs NFTs will be viewable in your wallet and on OpenSea.",
    },
    question_3: {
      title: "Will there be a presale?",
      text: "We are planning to have a presale and a public sale. More details on this very soon, stay tuned.",
    },
    question_4: {
      title: "When is the official launch?",
      text: "Presale and public sale dates will be announced very soon. Minting will be from the official website.",
    },
    question_5: {
      title: "Why are Shamanzs so unique?",
      text: "Shamanzs fall under a new category of NFTs that are designed and built slowly to ensure art quality, becoming visually appealing. Some experts in the NFT field think art is not key for NFTs to be successful. That might be true for a certain type of projects with different goals, however we have in mind growing a long lasting brand that puts aesthetics as the main pillar. The level of detail found in traits takes time inevitably, lots of love and care has been put in this project. We hope you appreciate it.",
    },
  },
};
