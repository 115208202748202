import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    discord: "https://discord.gg/shamanzs",
    twitter: "https://twitter.com/shamanzs",
    instagram: "https://www.instagram.com/shamanzsnft/",
    contract: "123",
  },
  mutations: {},
  actions: {},
  modules: {},
});
