<template>
  <div class="oracle wrapper">
    <div class="roadmap-shama absolute right-0 bottom-0 hidden lg:flex">
      <img width="200" src="@/assets/images/roadmap-shama.png" alt="" />
    </div>
    <div class="desktop-roadmap hidden lg:flex">
      <img src="@/assets/images/roadmap.png" alt="" />
    </div>
    <!-- <h1 class="font-Guru text-center text-5xl md:text-7xl mb-20">Roadmap</h1> -->
    <div class="mobile-roadmap lg:hidden">
      <div class="text-4xl font-black mb-6">Roadmap</div>
      <p class="lg:w-1/2">
        Our main pillar and motto is art. Brosmind has a very well-defined style
        that involves a lot of details on every trait and illustration,
        resulting in an iconic and time-proof piece of art.
      </p>
      <p class="lg:w-1/2 mt-4">
        The evolved roadmap has been redesigned to give back to the community,
        putting a long-term project in mind, a brand that will grow along with a
        tight knitted, good vibes only community. Holders will be rewarded in
        many ways, granting them exclusive access to future art and creativities
        that the artists and the team will continue building.
      </p>
      <div class="predictions flex flex-col w-full mt-20" data-aos="fade-up">
        <div class="prediction flex flex-col md:flex-row items-center">
          <img
            class="prediction-image mb-6 md:mb-0"
            src="@/assets/images/peace.png"
          />
          <div class="prediction-content pl-6">
            <h2 class="text-2xl font-black">GVO Community</h2>
            <h3 class="text-xl mb-4">The ShamaGang</h3>
            <p>
              The "Good Vibes Only" movement is building strong. The ShamaGang
              is growing every day, peace and love is the only way to defeat
              hate. The world is in need of positive vibes and good energy, and
              nothing represents it better than our community. We plan on
              becoming the "Good Vibes Only" NFT brand, this is our directive
              line and we will stick to it until the end.
            </p>
          </div>
        </div>
      </div>
      <div class="predictions flex flex-col w-full mt-20" data-aos="fade-up">
        <div class="prediction flex flex-col md:flex-row items-center">
          <img
            class="prediction-image mb-6 md:mb-0"
            src="@/assets/images/coin.png"
          />
          <div class="prediction-content pl-6">
            <h2 class="text-2xl font-black">Holders reward program</h2>
            <h3 class="text-xl mb-4">ShamaLovers</h3>
            <p>
              We are an art based collection, however we put our community
              first, and this is why we plan to provide additional utility. Here
              are some of the post-mint rewards you can expect as holder:
            </p>
            <ul class="list-disc mt-4 pl-4 text-sm">
              <li>Airdrops</li>
              <li>Merch gifts</li>
              <li>Discounts to be used on the online shop</li>
              <li>Passes to new art</li>
              <li>
                Access with benefits to our future physical and digital events
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="predictions flex flex-col w-full mt-20" data-aos="fade-up">
        <div class="prediction flex flex-col md:flex-row items-center">
          <img
            class="prediction-image mb-6 md:mb-0"
            src="@/assets/images/shamaverse.png"
          />
          <div class="prediction-content pl-6">
            <h2 class="text-2xl font-black">The Shamaverse</h2>
            <h3 class="text-xl mb-4">Awaken Ceremony at the Temple</h3>
            <p>
              The ShamaGang will keep growing both digitally and physically. We
              are committed to take the GVO culture to every corner possible.
              Artists, creative minds, and brands that align with our core
              values will be welcomed to join a life-changing movement that has
              just started.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "oracle",
};
</script>
<style scoped>
.prediction-image {
  width: 140px;
}
</style>
