<template>
  <div id="app" class="h-screen">
    <router-view />
    <up-arrow
      class="up-arrow cursor-pointer"
      :class="{ 'hide-arrow': !showArrow }"
      v-on:click.native="scrollToTop"
    />
  </div>
</template>
<script>
import upArrow from "@/components/svgs/upArrow";

export default {
  name: "app",
  data() {
    return {
      showArrow: false,
    };
  },
  components: { upArrow },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    scrollToTop: () => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    handleScroll() {
      window.scrollY > 200 ? (this.showArrow = true) : (this.showArrow = false);
    },
  },
};
</script>
<style scoped lang="scss">
.up-arrow {
  position: fixed;
  bottom: 30px;
  right: 30px;
  z-index: 999;
  transition: all 0.3s;
}
.hide-arrow {
  bottom: -200px;
}
</style>
