<template>
  <div class="footer">
    <div
      class="wrapper p-8 flex items-center justify-between flex-col md:flex-row"
    >
      <ul class="font-black flex items-center">
        <li>
          <a :href="$store.state.twitter"><twitter /></a>
        </li>
        <li>
          <a :href="$store.state.instagram"><instagram /></a>
        </li>
        <li>
          <a :href="$store.state.discord"><discord /></a>
        </li>
        <li>
          <router-link to="/toc">Terms and conditions</router-link>
        </li>
      </ul>
      <p class="md:mt-0 mt-4">Shamanzs 2022, All rights reserved</p>
    </div>
  </div>
</template>
<script>
import twitter from "./svgs/twitter";
import instagram from "./svgs/instagram";
import discord from "./svgs/discord";
export default {
  name: "foot",
  components: {
    twitter,
    instagram,
    discord,
  },
};
</script>
<style lang="scss" scoped>
li {
  @apply px-4;
}
</style>
