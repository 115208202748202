<template>
  <div class="brosmind wrapper flex flex-col">
    <div class="flex flex-col lg:flex-row">
      <div class="lg:w-1/2 lg:pr-4 z-10">
        <img
          class="rounded transform -rotate-3"
          src="@/assets/images/brosmind.png"
          alt="brosmind teams"
        />
      </div>
      <div class="lg:w-1/2 lg:pl-4 lg:text-right mt-10 lg:mt-0 relative z-10">
        <!--<img class="bit-1 hidden lg:flex" src="@/assets/images/bit-1.png" />-->
        <div class="title z-10">Design Masters</div>
        <p class="z-10">
          Barcelona based award-winning design studio Brosmind, is led by
          brothers Juan and Alejandro. Their work has been admired and hired by
          international companies from all over the world: Nike, Adidas,
          Coca-Cola, Google, Ray-Ban, National Geographic, Landrover, Facebook,
          Honda, Mobile World Congress, Mailchimp, and many more.
        </p>
        <ul class="mt-6 social-media-list">
          <li>
            <a href="https://twitter.com/Brosmind" target="_blank"
              ><twitter
            /></a>
          </li>
          <li>
            <a href="https://www.instagram.com/brosmind/" target="_blank"
              ><instagram
            /></a>
          </li>
          <li>
            <a href="https://www.brosmind.com/" target="_blank"><web /></a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import twitter from "./svgs/twitter";
import instagram from "./svgs/instagram";
import web from "./svgs/web";
export default {
  name: "Brosmind",
  components: { twitter, instagram, web },
};
</script>
<style scoped lang="scss">
.social-media-list li {
  @apply inline-block ml-4;
}
.bit-1 {
  position: absolute;
  z-index: -1;
  top: -120px;
  right: -80px;
}
</style>
