<template>
  <div class="home pattern">
    <!-- <comming-soon /> -->
    <hero />
    <div class="pattern">
      <minting-details data-aos="fade-up" class="mb-32" />
      <intro data-aos="fade-up" class="separator-padding mt-32" />
      <div id="guruzs" class="pb-4" />
      <about data-aos="fade-up" class="separator-padding" />
      <div id="brosmind" class="pb-4" />
      <brosmind data-aos="fade-up" class="separator-padding" />
      <!-- <discord-banner data-aos="fade-up" class="separator-padding" /> -->
      <div id="roadmap" class="pb-4" />
      <oracle data-aos="fade-up" class="separator-padding" />
      <!-- <roadmap data-aos="fade-up" class="pb-10" /> -->
      <!-- <roadmap-image data-aos="fade-up" class="mb-32 lg:mb-48" /> -->
      <!-- <discord-banner data-aos="fade-up" class="separator-padding" /> -->
      <div id="team" class="pb-4" />
      <team data-aos="fade-up" class="separator-padding" />
      <!-- <div id="ambassadors" class="pb-4" /> -->
      <!-- <colaborators data-aos="fade-up" class="separator-padding" /> -->
      <div id="faq" class="pb-4" />
      <faq data-aos="fade-up" class="separator-padding" />
    </div>
    <footer-c />
  </div>
</template>
<script>
// import commingSoon from "../components/common/CommingSoon.vue";
import hero from "../components/common/Hero";
import mintingDetails from "../components/minting-details.vue";
import brosmind from "../components/Brosmind";
import about from "../components/About";
// import discordBanner from "../components/common/DiscordBanner";
// import roadmap from "../components/Roadmap";
// import roadmapImage from "../components/RoadmapImage";
import oracle from "../components/Oracle";
import faq from "../components/faq";
import team from "../components/Team";
import footerC from "../components/Footer";
import intro from "../components/Intro";
// import colaborators from "../components/Colaborators.vue";
export default {
  name: "home",
  components: {
    hero,
    mintingDetails,
    brosmind,
    about,
    // discordBanner,
    //roadmap,
    oracle,
    // roadmapImage,
    faq,
    team,
    footerC,
    intro,
    // colaborators,
    // commingSoon,
  },
};
</script>
<style scoped>
.separator-padding {
  @apply pb-32 lg:pb-48;
}
</style>
