<template>
  <header class="top-bar">
    <div class="content flex flex-row justify-between items-center">
      <logo-svg class="logo z-10" />
      <main-menu class="self-start mt-4" />
    </div>
  </header>
</template>
<script>
import logoSvg from "../svgs/logo";
import mainMenu from "./Menu";
export default {
  name: "top-bar",
  components: { logoSvg, mainMenu },
};
</script>
