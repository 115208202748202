import Vue from "vue";
import VueI18n from "vue-i18n";

import es from "./es";
import en from "./en";

Vue.use(VueI18n);

const messages = {
  es,
  en,
};

export let i18n = new VueI18n({
  locale: "en",
  fallbackLocale: "en",
  silentTranslationWarn: true,
  messages,
});
