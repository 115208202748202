<template>
  <div class="team wrapper flex flex-col">
    <div class="title text-right">{{ $t("team.title") }}</div>
    <p class="lg:w-1/2 text-right self-end">
      {{ $t("team.text") }}
    </p>
    <div class="team-wrapper flex flex-row flex-wrap justify-center mt-10">
      <team-member
        class="w-1/4"
        :image="require('@/assets/images/ricard.png')"
        name="Rich Sham"
        twitter="https://twitter.com/TheRichShamanz"
        position="Co-founder"
      >
      </team-member>
      <team-member
        class="w-1/4"
        :image="require('@/assets/images/dani.png')"
        name="Shamanizer"
        twitter="https://twitter.com/Shamanizer_NFT"
        position="Co-founder"
      >
        <p>
          Gloria is our NFT expert, with sold out collections behind her back
        </p>
      </team-member>
      <team-member
        class="w-1/4"
        :image="require('@/assets/images/ale.png')"
        name="Ale"
        twitter="https://twitter.com/Brosmind"
        position="Artist"
      >
        <p>
          Gloria is our NFT expert, with sold out collections behind her back
        </p>
      </team-member>
      <team-member
        class="w-1/4"
        :image="require('@/assets/images/juan.png')"
        name="Juan"
        twitter="https://twitter.com/Brosmind"
        position="Artist"
      >
      </team-member>
      <team-member
        class="w-1/4"
        :image="require('@/assets/images/bigshama.png')"
        name="BigShama"
        position="Developer"
      >
      </team-member>
    </div>
  </div>
</template>
<script>
import teamMember from "./teamMember.vue";
export default {
  name: "teams",
  components: { teamMember },
};
</script>
<style lang="scss" scoped>
.empty-team-member {
  height: 0 !important;
}
</style>
