<template>
  <div class="toc pattern">
    <header class="pt-4 container mx-auto pr-10 xxl:pr-0 z-10">
      <div class="content flex flex-row justify-between items-center">
        <logo-svg class="logo z-10" />
        <router-link
          class="back-link self-start mt-3 font-black text-white"
          to="/"
          >Back</router-link
        >
      </div>
    </header>
    <h1 class="font-Guru text-center text-5xl md:text-7xl mb-10 wrapper">
      SHAMANZS TERMS AND CONDITIONS
    </h1>
    <section class="wrapper">
      <h2 class="font-Guru text-xl mb-4">1. Definitions.</h2>
      <p>
        “Art” means any art, design, and drawings that may be associated with an
        NFT that you Own.
      </p>
      <p>
        "NFT" means any blockchain-tracked, non-fungible token, such as those
        conforming to the ERC-721 standard.
      </p>
      <p>
        “Own” means, with respect to an NFT, an NFT that you have purchased or
        otherwise rightfully acquired from a legitimate source, where proof of
        such purchase is recorded on the relevant blockchain.
      </p>
      <p>
        “Extensions” means third party designs that: (i) are intended for use as
        extensions or overlays to the Art, (ii) do not modify the underlying
        Art, and (iii) can be removed at any time without affecting the
        underlying Art.
      </p>
      <p>“Purchased NFT” means an NFT that you Own.</p>
      <p>
        “Third Party IP” means any third party patent rights (including, without
        limitation, patent applications and disclosures), copyrights, trade
        secrets, trademarks, know-how or any other intellectual property rights
        recognized in any country or jurisdiction in the world.
      </p>
    </section>
    <section class="wrapper mt-6">
      <h2 class="font-Guru text-xl mb-4">2. Ownership.</h2>
      <p>
        You acknowledge and agree that Shamanzs (or, as applicable, its
        licensors) owns all legal right, title and interest in and to the Art,
        and all intellectual property rights therein. The rights that you have
        in and to the Art are limited to those described in this License.
        Shamanzs reserves all rights in and to the Art not expressly granted to
        you in this License.
      </p>
    </section>
    <section class="wrapper mt-6">
      <h2 class="font-Guru text-xl mb-4">3. License.</h2>
      <p>
        <span class="font-black">a. General Use.</span> Subject to your
        continued compliance with the terms of this License, Shamanzs grants you
        a worldwide, non-exclusive, non-transferable, royalty-free license to
        use, copy, and display the Art for your Purchased NFTs, along with any
        Extensions that you choose to create or use, solely for the following
        purposes: (i) for your own personal, non-commercial use; (ii) as part of
        a marketplace that permits the purchase and sale of your NFTs, provided
        that the marketplace cryptographically verifies each NFT owner’s rights
        to display the Art for their Purchased NFTs to ensure that only the
        actual owner can display the Art; or (iii) as part of a third party
        website or application that permits the inclusion, involvement, or
        participation of your NFTs, provided that the website/application
        cryptographically verifies each NFT owner’s rights to display the Art
        for their Purchased NFTs to ensure that only the actual owner can
        display the Art, and provided that the Art is no longer visible once the
        owner of the Purchased NFT leaves the website/application.
      </p>
      <p>
        <span class="font-black">b. Commercial Use.</span> Subject to your
        continued compliance with the terms of this License, Shamanzs grants you
        a limited, worldwide, non-exclusive, non-transferable license to use,
        copy, and display the Art for your Purchased NFTs for the purpose of
        commercializing your own merchandise that includes, contains, or
        consists of the Art for your Purchased NFTs (“Commercial Use”), provided
        that such Commercial Use does not result in you earning more than One
        Hundred Thousand Dollars ($100,000) in gross revenue each year. For the
        sake of clarity, nothing in this Section 3.b will be deemed to restrict
        you from (i) owning or operating a marketplace that permits the use and
        sale of NFTs generally, provided that the marketplace cryptographically
        verifies each NFT owner’s rights to display the Art for their Purchased
        NFTs to ensure that only the actual owner can display the Art; (ii)
        owning or operating a third party website or application that permits
        the inclusion, involvement, or participation of NFTs generally, provided
        that the third party website or application cryptographically verifies
        each NFT owner’s rights to display the Art for their Purchased NFTs to
        ensure that only the actual owner can display the Art, and provided that
        the Art is no longer visible once the owner of the Purchased NFT leaves
        the website/application; or (iii) earning revenue from any of the
        foregoing, even where such revenue is in excess of $100,000 per year.
      </p>
    </section>
    <section class="wrapper mt-6">
      <h2 class="font-Guru text-xl mb-4">4. Restrictions.</h2>
      <p>
        You agree that you may not, nor permit any third party to do or attempt
        to do any of the foregoing without Shamanzs’ express prior written
        consent in each case: (i) modify the Art for your Purchased NFT in any
        way, including, without limitation, the shapes, designs, drawings,
        attributes, or color schemes (your use of Extensions will not constitute
        a prohibited modification hereunder); (ii) use the Art for your
        Purchased NFTs to advertise, market, or sell any third party product or
        service; (iii) use the Art for your Purchased NFTs in connection with
        images, videos, or other forms of media that depict hatred, intolerance,
        violence, cruelty, or anything else that could reasonably be found to
        constitute hate speech or otherwise infringe upon the rights of others;
        (iv) use the Art for your Purchased NFTs in movies, videos, or any other
        forms of media, except to the limited extent that such use is expressly
        permitted in Section 3(b) above or solely for your own personal,
        non-commercial use; (v) sell, distribute for commercial gain (including,
        without limitation, giving away in the hopes of eventual commercial
        gain), or otherwise commercialize merchandise that includes, contains,
        or consists of the Art for your Purchased NFTs, except as expressly
        permitted in Section 3(b) above; (vi) attempt to trademark, copyright,
        or otherwise acquire additional intellectual property rights in or to
        the Art for your Purchased NFTs; or (vii) otherwise utilize the Art for
        your Purchased NFTs for your or any third party’s commercial benefit. To
        the extent that Art associated with your Purchased NFTs contains Third
        Party IP (e.g., licensed intellectual property from a celebrity,
        athlete, or other public figure), you understand and agree as follows:
        (w) that you will not have the right to use such Third Party IP in any
        way except as incorporated in the Art, and subject to the license and
        restrictions contained herein; (x) that the Commercial Use license in
        Section 3(b) above will not apply; (y) that, depending on the nature of
        the license granted from the owner of the Third Party IP, Shamanzs may
        need to pass through additional restrictions on your ability to use the
        Art; and (z) to the extent that Shamanzs informs you of such additional
        restrictions in writing (email is permissible), you will be responsible
        for complying with all such restrictions from the date that you receive
        the notice, and that failure to do so will be deemed a breach of this
        license. The restriction in Section 4 will survive the expiration or
        termination of this License.
      </p>
    </section>
    <section class="wrapper mt-6">
      <h2 class="font-Guru text-xl mb-4">5. Terms of License.</h2>
      <p>
        The license granted in Section 3 above applies only to the extent that
        you continue to Own the applicable Purchased NFT. If at any time you
        sell, trade, donate, give away, transfer, or otherwise dispose of your
        Purchased NFT for any reason, the license granted in Section 3 will
        immediately expire with respect to those NFTs without the requirement of
        notice, and you will have no further rights in or to the Art for those
        NFTs. If you exceed the $100,000 limitation on annual gross revenue set
        forth in Section 3.b above, you will be in breach of this License, and
        must send an email to Shamanzs at hello@shamanzs.com within fifteen (15)
        days, with the phrase “NFT License - Commercial Use” in the subject
        line, requesting a discussion with Shamanzs regarding entering into a
        broader license agreement or obtaining an exemption (which may be
        granted or withheld in Shamanzs’ sole and absolute discretion). If you
        exceed the scope of the license grant in Section 3.b without entering
        into a broader license agreement with or obtaining an exemption from
        Shamanzs, you acknowledge and agree that: (i) you are in breach of this
        License; (ii) in addition to any remedies that may be available to
        Shamanzs at law or in equity, the Shamanzs may immediately terminate
        this License, without the requirement of notice; and (iii) you will be
        responsible to reimburse Shamanzs for any costs and expenses incurred by
        Shamanzs during the course of enforcing the terms of this License
        against you.
      </p>
    </section>
    <section class="wrapper mt-6">
      <h2 class="font-Guru text-xl mb-4">FAQ</h2>
      <p class="font-black text-xl">
        What am I allowed to do with the art associated with my NFT?
      </p>
      <p>
        You have broad rights to use the art associated with your NFT. In the
        case of Shamanzs, you can do any of the following:
      </p>
      <ul class="list-disc">
        <li>Use the Shamanzs art for your own personal, non-commercial use;</li>
        <li>
          Use the Shamanzs art when you’re on a marketplace that allows the
          purchase and sale of your Shamanzs, so long as the marketplace
          cryptographically verifies that you are the owner;
        </li>
        <li>
          Use the Shamanzs art when you’re on a third party website or app that
          allows the inclusion, involvement, or participation of your Shamanzs,
          so long as the website/app cryptographically verifies that you are the
          owner, and the Shamanzs art doesn’t stay on the website/app after
          you’ve left; and
        </li>
        <li>
          Use the Shamanzs art to commercialize your own merchandise, provided
          that you aren’t earning more than $100,000 in revenue each year from
          doing so.
        </li>
      </ul>
      <p class="font-black text-xl">
        What am I NOT allowed to do with the art associated with my NFT?
      </p>
      <p>
        There are a few things that aren’t appropriate uses for your NFT art.
        They include:
      </p>
      <ul class="list-disc">
        <li>Modifying the art;</li>
        <li>Using the art to market or sell third-party products;</li>
        <li>
          Using the art in connection with images of hatred, violence, or other
          inappropriate behavior; or
        </li>
        <li>
          Trying to trademark your art, or otherwise acquire intellectual
          property rights in it.
        </li>
      </ul>
      <p class="font-black text-xl">
        What does “personal, non-commercial use” mean?
      </p>
      <p>
        It’s personal, non-commercial use if you’re copying or using your NFT
        art in a way that doesn’t involve selling or using it (or trying to sell
        or use it) for commercial gain. Some examples of personal,
        non-commercial use include:
      </p>
      <ul class="list-disc">
        <li>
          Printing a copy of your NFT art on a t-shirt or hat that you wear
          yourself, or give to a small group of friends;
        </li>
        <li>
          Commissioning a famous painter to paint a special painting of your
          Shamanzs art, that you put on your wall at home; or
        </li>
        <li>
          Making a video using your Shamanzs art, that you share with your
          family or a small group of friends.
        </li>
      </ul>
      <p class="font-black text-xl">
        What does “cryptographically verify” mean, and why is it important?
      </p>
      <p>
        The goal is to make sure that you enjoy the full benefits of NFT
        ownership. First and foremost, that means making every effort to make
        sure that only the real owner of the NFT gets to use the art associated
        with it. We want any marketplace or third party site/app that
        incorporates NFTs to check to confirm that anyone placing art on that
        marketplace/site/app actually owns the associated NFT. They can do this
        by checking the blockchain record. This way, only you can use the NFT
        art you own.
      </p>
      <p class="font-black text-xl">Why can’t I modify the art of my NFT?</p>
      <p>
        Just like your NFT, the art is unique. If anyone could simply change
        their own NFT to look like yours, that wouldn’t be cool. So, we ask that
        you don’t change your art to look like anyone else’s! If you want a NFT
        with different attributes, have fun creating it yourself the way the
        game intended!
      </p>
      <p class="font-black text-xl">Am I allowed to commercialize my art?</p>
      <p>
        Yes, so long as you’re earning less than $100,000 in gross revenue per
        year as a result of your efforts. If you’re earning (or think you might
        earn) more than that, you’ll need to contact Shamanzs and negotiate a
        special commercial use license. This special license might include a
        revenue share, a royalty payment, or other applicable terms.
      </p>
      <p class="font-black text-xl">
        Why is there a restriction on how much money I can make using my art?
      </p>
      <p>
        Shamanzs created the art of your NFT, and it’s likely a big part of
        their brand. Commercializing your art is exciting, but at the end of the
        day, businesses also need to have the right to make the big decisions
        about how the art gets used in a commercial setting.
      </p>
      <p>
        We’ve tried to balance these interests by allowing you to make up to
        $100,000 a year off of your art, while we reserve to ourselves the right
        to make bigger deals to help our business grow.
      </p>
      <p class="font-black text-xl">
        How do you know how much I’m earning from my art? How will you enforce
        this rule?
      </p>
      <p>
        For now, we’re operating on the honor system. We expect you to be
        honest, and to respect the fine balance we’re trying to create. If we
        discover that you’re not complying with the license in some way, we
        reserve the right to terminate the license. If that happens, while
        you’ll still own your NFT, you’ll lose the rights to use the art
        associated with it.
      </p>
      <p class="font-black text-xl">
        I want to create a new marketplace, website, or application that
        involves the purchase and sale of NFTs, or the inclusion, involvement,
        or participation of NFTs. Is that restricted, too?
      </p>
      <p>
        There is no restriction. The revenue limitation in the license only
        governs people who are trying to commercialize their own art - not
        people who are trying to create new and unique experiences for Shamanzs
        or other NFTs. We encourage you to create marketplaces, websites, and
        applications that involve the purchase, sale, inclusion, involvement, or
        participation of Shamanzs and other NFTs!
      </p>
    </section>
    <footer-c class="separator-padding mt-32" />
  </div>
</template>
<script>
import footerC from "@/components/Footer";
import logoSvg from "@/components/svgs/logo";
export default {
  name: "toc",
  components: { footerC, logoSvg },
};
</script>
<style scoped>
p,
ul {
  @apply py-2;
}
</style>
