import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { i18n } from "./locales";
import "./assets/styles/style.css";
import AOS from "aos";
import "aos/dist/aos.css";
import device from "vue-device-detector";
require("vue2-animate/dist/vue2-animate.min.css");

Vue.config.productionTip = false;
Vue.use(device);
new Vue({
  router,
  store,
  i18n,
  created() {
    AOS.init();
  },
  render: (h) => h(App),
}).$mount("#app");
