import hero from "./hero";
import menu from "./menu";
import brosmind from "./brosmind";
import about from "./about";
import roadmap from "./roadmap";
import oracle from "./oracle";
import faq from "./faq";
import team from "./team";
import intro from "./intro";
export default {
  hero,
  menu,
  brosmind,
  about,
  roadmap,
  oracle,
  faq,
  team,
  intro,
};
