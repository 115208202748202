export default {
  title: "The Story",
  point1:
    "All Shamanzs follow the ERC721 NFT standard. That means adaptability and durability are ensured.",
  point2:
    "The team is planning a strategy to offer as much distribution as possible during minting stage.",
  point3:
    "Each NFT will be your secret pass to the Shamaverse, which will combine both digital and physical world utility.",
  paragraph1:
    "The wisest Monkzs, Sadhuzs, Godzs and Guruzs on spiritual land, no matter which ancient religion they belong to, are secretly joining forces to create a powerful unified legion. Their goal is to spread love and good vibezs to erase bad energies from mother Earth for once. A new army of Shamanzs is secretly emerging, and the largest community of followerzs ever seen, is about to enlighten the whole metaverse with limitless positive energy.",
  paragraph2:
    "Leaders from different tribes, beliefs, religions, backgrounds and natures are fusing in an evolved and upgraded version; self-proclaimed as SHAMANZS.",
  paragraph3:
    "The unstoppable wave of positiveness radiated all over the world by the legion of SHAMANZS, is catching up the attention of dormant ancient gods, who knows what can happen next…",
};
