export default {
  title: "Development Roadmap",
  text: "is both the long term roadmap and vision of the brand. Owning and holding an NFT, will give you access to the Shamaverse. Our goal is to build a strong and beloved brand, working towards several valuable marketing strategies such as partnering with DJs, record labels and music festivals, trendy hospitality brands, clothing brands, and many more yet to come.",
  prediction_1: {
    title: "Brand Collabs",
    text: "NFTs are a great exposure to showcase the potential growth of Shamanzs. Partnerships are the next step.",
  },
  prediction_2: {
    title: "Utility Token",
    text: "Rewards in form of a token could be used in the Shamaverse, both digitally and physically.",
  },
  prediction_3: {
    title: "Secret Eventzs",
    text: "Magic locations around the world, are awaiting the ShamaGang and their friends to party as never before.",
  },
};
