<template>
  <div class="main-menu">
    <menu-icon
      class="menu-icon xl:hidden cursor-pointer"
      v-on:click.native="shown = !shown"
    />
    <ul class="desktop-menu font-black hidden xl:flex">
      <li>
        <a v-on:click.prevent="scrollTo('guruzs')">{{
          $t("menu.about_guruzs")
        }}</a>
      </li>
      <li>
        <a v-on:click.prevent="scrollTo('brosmind')">{{
          $t("menu.meet_brosmind")
        }}</a>
      </li>
      <li>
        <router-link to="/lore">Lore</router-link>
      </li>
      <li>
        <a v-on:click.prevent="scrollTo('roadmap')">Roadmap</a>
      </li>
      <li>
        <a v-on:click.prevent="scrollTo('team')">{{ $t("menu.team") }}</a>
      </li>
      <li>
        <a v-on:click.prevent="scrollTo('faq')">{{ $t("menu.faq") }}</a>
      </li>
      <!-- <li>
        <a v-on:click.prevent="scrollTo('ambassadors')">Ambassadors</a>
      </li> -->
      <li>
        <a :href="$store.state.twitter"><twitter /></a>
      </li>
      <li>
        <a :href="$store.state.instagram"><instagram /></a>
      </li>
      <li>
        <a :href="$store.state.discord"><discord /></a>
      </li>
    </ul>
    <ul
      class="mobile-menu font-black xl:hidden"
      :class="{ 'mobile-menu-shown': shown }"
    >
      <li>
        <close class="cursor-pointer" v-on:click.native="shown = !shown" />
      </li>
      <li>
        <a v-on:click.prevent="scrollTo('guruzs')">{{
          $t("menu.about_guruzs")
        }}</a>
      </li>
      <li>
        <a v-on:click.prevent="scrollTo('brosmind')">{{
          $t("menu.meet_brosmind")
        }}</a>
      </li>
      <li>
        <router-link to="/lore">Lore</router-link>
      </li>
      <li>
        <a v-on:click.prevent="scrollTo('roadmap')">Roadmap</a>
      </li>
      <li>
        <a v-on:click.prevent="scrollTo('team')">{{ $t("menu.team") }}</a>
      </li>
      <li>
        <a v-on:click.prevent="scrollTo('faq')">{{ $t("menu.faq") }}</a>
      </li>
      <!-- <li>
        <a v-on:click.prevent="scrollTo('ambassadors')">Ambassadors</a>
      </li> -->
      <li>
        <a :href="$store.state.twitter"><twitter /></a>
      </li>
      <li>
        <a :href="$store.state.instagram"><instagram /></a>
      </li>
      <li>
        <a :href="$store.state.discord"><discord /></a>
      </li>
    </ul>
  </div>
</template>
<script>
import menuIcon from "../svgs/menuIcon";
import twitter from "../svgs/twitter";
import instagram from "../svgs/instagram";
import discord from "../svgs/discord";
import close from "../svgs/close";
export default {
  name: "main-menu",
  data: () => {
    return {
      shown: false,
    };
  },
  components: {
    menuIcon,
    twitter,
    instagram,
    discord,
    close,
  },
  methods: {
    scrollTo: (div) => {
      document.getElementById(div).scrollIntoView({ behavior: "smooth" });
    },
  },
};
</script>
<style scoped lang="scss">
.main-menu {
  z-index: 1000;
}
.menu-icon {
  z-index: 200;
}
.desktop-menu li {
  a {
    cursor: pointer;
  }
  @apply inline-block ml-4 flex items-center;
}
.mobile-menu {
  position: fixed;
  height: 100%;
  width: 60%;
  padding: 20px;
  right: -1000px;
  top: 0;
  z-index: 1000;
  background: black;
  color: white;
  transition: all 0.3s;
}
.mobile-menu-shown {
  right: 0;
}
.mobile-menu li {
  margin-bottom: 20px;
  a {
    cursor: pointer;
  }
}
</style>
