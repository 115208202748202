<template>
  <div class="question" data-aos="fade-up">
    <div class="flex justify-between flex-row">
      <h2 class="w-4/5">{{ title }}</h2>
      <plus
        :active="showText"
        class="cursor-pointer"
        @click.native="showText = !showText"
      />
    </div>
    <transition name="fade">
      <div class="mb-4" v-show="showText" style="animation-duration: 0.3s">
        <slot />
      </div>
    </transition>
  </div>
</template>
<script>
import plus from "../components/svgs/plus.vue";
export default {
  name: "faq-question",
  components: { plus },
  data() {
    return {
      showText: false,
    };
  },
  props: {
    title: {
      type: String,
    },
  },
};
</script>
<style lang="scss" scoped>
.question {
  @apply pt-4;
}
.question h2 {
  @apply text-xl font-black mb-4;
}
</style>
