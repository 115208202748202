<template>
  <section class="hero flex flex-col h-screen">
    <top-bar class="pt-10 container mx-auto pr-10 xxl:pr-0" />
    <div class="video-wrapper w-full h-full flex justify-center">
      <transition
        enter-active-class="animated fadeInUp"
        leave-active-class="animated fadeOutDown"
      >
        <video
          style="animation-duration: 0.3s"
          v-show="showVideo"
          class="shamanz-video h-2/3 shadow-xl z-10"
          id="videoPlayer"
          fullscreen="true"
          @click="showShamanzsVideo"
        >
          <source :src="teaser" type="video/mp4" />
        </video>
      </transition>
    </div>
    <video
      class="guru-video"
      poster="@/assets/images/shamanz-bg.jpg"
      autoplay
      playsinline
      muted
      loop
    >
      <source :src="videoBg" type="video/mp4" />
    </video>
    <transition
      enter-active-class="animated fadeInUp"
      leave-active-class="animated fadeOutDown"
    >
      >
      <div
        style="animation-duration: 0.3s"
        v-show="!showVideo"
        class="video-wrapper flex justiify-center items-center flex-col bg-black bg-opacity-50 p-6 z-10"
      >
        <play-button
          class="play-button w-20 my-4 cursor-pointer"
          @click.native="showShamanzsVideo"
        />
        <p class="font-Guru text-xl pb-2 text-center">Watch the teaser!</p>
      </div>
    </transition>
  </section>
</template>
<script>
import topBar from "./TopBar";
import playButton from "../svgs/playButton";
export default {
  name: "hero",
  components: { topBar, playButton },
  data() {
    return {
      showVideo: false,
    };
  },
  computed: {
    teaser: function () {
      return this.$device.mobile
        ? require("@/assets/videos/Schamanzs_1600.mp4")
        : require("@/assets/videos/Schamanzs_1920.mp4");
    },
    videoBg: function () {
      console.log(this.$device.mobile);
      return this.$device.mobile
        ? require("@/assets/images/Schamanz_loop_1600.mp4")
        : require("@/assets/images/Schamanz_loop.mp4");
    },
  },
  methods: {
    showShamanzsVideo() {
      var videoPlayer = document.getElementById("videoPlayer");
      this.showVideo = !this.showVideo;
      this.showVideo ? videoPlayer.play() : videoPlayer.pause();
      videoPlayer.currentTime = 0;
    },
  },
};
</script>
