<template>
  <a class="flex flex-row items-center" href="/">
    <lottie-animation
      id="animated-logo"
      ref="anim"
      :animationData="require('@/assets/images/logo.json')"
      :loop="false"
      :autoPlay="true"
    />
    <transition name="fadeLeft">
      <shamanz
        id="animated-text"
        style="animation-duration: 0.3s"
        v-show="showText"
        class="self-start mt-4 flex"
      />
    </transition>
  </a>
</template>
<script>
import LottieAnimation from "lottie-web-vue";
import shamanz from "./shamanz.vue";
export default {
  data() {
    return {
      showText: false,
    };
  },
  mounted() {
    setTimeout(() => {
      this.showText = true;
    }, 1250);
  },
  components: {
    LottieAnimation,
    shamanz,
  },
};
</script>
<style lang="scss" scoped>
#animated-logo {
  width: 80px !important;
}
#animated-text {
  height: 20px;
}
@media only screen and (min-width: 768px) {
  #animated-logo {
    width: 120px !important;
  }
  #animated-text {
    height: 30px;
  }
}
</style>
