<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="36"
    height="36"
    viewBox="0 0 24 24"
  >
    <g transform="translate(-351.005 -5959)">
      <path
        v-show="!active"
        d="M363.005,5966.5v9"
        fill="none"
        stroke="#fff"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.5"
      />
      <path
        d="M358.505,5971h9"
        fill="none"
        stroke="#fff"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.5"
      />
      <path
        d="M363.005,5982.25a11.25,11.25,0,1,0-11.25-11.25A11.25,11.25,0,0,0,363.005,5982.25Z"
        fill="none"
        stroke="#fff"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.5"
      />
    </g>
  </svg>
</template>
<script>
export default {
  name: "plus-icon",
  props: {
    active: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style lang="scss" scoped>
svg:hover {
  g path {
    stroke: #14fbae;
  }
}
</style>
