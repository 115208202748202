<template>
  <div class="parallax_wrapper overflow-x-hidden">
    <!-- -->
    <div class="g1 overflow-hidden relative">
      <header class="pt-4 container mx-auto pr-10 xxl:pr-0 z-10">
        <div class="content flex flex-row justify-between items-center">
          <logo-svg class="logo z-10" />
          <router-link
            class="back-link self-start mt-3 font-black text-white"
            to="/"
          >
            Back
          </router-link>
        </div>
      </header>
      <div
        class="shamanizerg1 translate"
        :style="`transform:translateY(${top * -0.5}px)`"
      />
      <div
        class="textg1 h-screen w-full flex items-center justify-center absolute translate"
        :style="`transform:translateY(${top * 0.2}px)`"
      >
        <h1 class="text-4xl md:text-8xl font-Guru">Genesis I</h1>
      </div>
      <div
        class="translate shamanzsg1"
        :style="`transform:translateY(${top * 2}px)`"
      />
    </div>
    <div
      class="g1-text relative flex flex-col items-center justify-center py-32 px-4 pattern text-center"
    >
      <p class="font-black text-2xl mb-6 md:w-1/3">The Shamanizer</p>
      <p class="md:w-1/3">
        He is the one in charge of transmitting the wisdom from ancient gods to
        Shamanzs. The chosen one. The master of good vibezs, the wisest of all.
        Shamanzs are now the keepers of a unique knowledge towards endless
        happiness.
      </p>
    </div>
    <!-- -->
    <div class="g1 overflow-hidden relative">
      <div
        class="translate shamanzsg2 z-1"
        :style="`transform:translateY(${top * 0.2}px)`"
      />
      <div
        class="textg1 h-screen w-full flex items-center justify-center absolute translate"
        :style="`transform:translateY(${top * -0.1}px)`"
      >
        <h1 class="text-4xl md:text-8xl font-Guru">Genesis II</h1>
      </div>
      <div
        class="shamanizerg2 translate"
        :style="`transform:translateX(${top * -0.5}px)`"
      />
    </div>
    <div
      class="g1-text relative flex flex-col items-center justify-center py-32 px-4 pattern text-center"
    >
      <p class="font-black text-2xl mb-6 md:w-1/3">The little fella</p>
      <p class="md:w-1/3">
        Is now in charge and ready to lead the #ShamaGang towards the promised
        land: “The Shamaverse”. The ones who know have now started their
        journey, and The Shamanizer is the only one who can guide them. We’re
        all in it together #GVO
      </p>
    </div>
    <!-- -->
    <div class="g2 overflow-hidden relative">
      <div
        class="translate shamanzsg3 z-1"
        :style="`transform:scale(${(top / 120) * 0.1})`"
      />
      <div
        class="textg1 h-screen w-full flex items-center justify-center absolute translate"
        :style="`transform:translateY(${top * -0.1}px)`"
      >
        <h1 class="text-4xl md:text-8xl font-Guru">Genesis III</h1>
      </div>
    </div>
    <div
      class="g1-text relative flex flex-col items-center justify-center py-32 px-4 pattern text-center"
    >
      <p class="font-black text-2xl mb-6 md:w-1/3">The trip</p>
      <p class="md:w-1/3">
        Towards Promised Land has started. All Shamanzs were guided by the
        little fella and now they find themselves a step closer to eternal
        happiness. However, before landing on the other side, the sacred gate
        must be crossed. All bad energies, negative thoughts and sad memories
        should be left behind; the sacred gate will get rid of them all.
        Upgraded minds and souls supercharged with GVO is what sets the new
        grounds of the ShamaGang.
      </p>
      <p class="md:w-1/3 pt-6 font-black">No Shamanzs will be left behind.</p>
    </div>
    <!-- -->
    <div class="g4 overflow-hidden relative">
      <div class="translate skyg4 z-2" />
      <div
        class="translate shamanizerg4 z-3"
        :style="`transform:translateY(${top * -0.1}px)`"
      />
      <div
        class="translate shamanzsg4 z-1"
        :style="`transform:translateY(${top * 0.1}px)`"
      />
      <div
        class="textg4 h-full w-full flex items-center justify-center absolute translate"
        :style="`transform:translateY(${top * -0.1}px)`"
      >
        <h1 class="text-4xl md:text-8xl font-Guru">Genesis IV</h1>
      </div>
    </div>
    <div
      class="g1-text relative flex flex-col items-center justify-center py-32 px-4 pattern text-center"
    >
      <p class="font-black text-2xl mb-6 md:w-1/3">Let the heart awaken</p>
      <p class="md:w-1/3">
        The language of silence, the language of love. The journey begins today.
        Let those communications awaken with you, see the beauty, the colours,
        the sound 🙏👁️ Look at the reflections that come to you. Nothing is
        going to be the same. Get ready ☮️
      </p>
    </div>
    <!-- -->
    <div class="g4 overflow-hidden relative">
      <div class="translate skyg5 z-2" />
      <div class="translate shamanizerg5 z-3" :style="`transform:translateY(${top * -0.18}px)`" />
      <div class="translate shamanzsg5 z-1" :style="`transform:translateY(${top * 0.1}px)`" />

      <div class="textg5 h-full w-full flex items-center justify-center absolute translate" :style="`transform:translateY(${top * -0.1}px)`" >
        <h1 class="text-4xl md:text-8xl font-Guru">Genesis V</h1>
      </div>

    </div>
    <div class="g1-text relative flex flex-col items-center justify-center py-32 px-4 pattern text-center" >
      <p class="font-black text-2xl mb-6 md:w-1/3">Let the green elixir into your body and soul: Shamahuasca</p>
      <p class="md:w-1/3"> Shamanzs are the ultimate seekers of freedom and endless joy. Certainly, not an easy thing to achieve. Wisdom, persistence, and patience are just a few of the skills you need to get the real journey started. Only those who know, will get there. And when you finally arrive, the reward is beyond anyone’s imagination. </p>
    </div>

    <footer-c class="separator-padding" />
  </div>
</template>
<script>
import footerC from '@/components/Footer'
import logoSvg from '@/components/svgs/logo'
export default {
  name: 'lore',
  components: { footerC, logoSvg },
  data() {
    return {
      top: window.pageYOffset,
    }
  },
  methods: {
    handleScroll() {
      this.top = window.pageYOffset
    },
  },
  created() {
    window.addEventListener('scroll', this.handleScroll)
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll)
  },
}
</script>
<style scoped>
.back-link {
  z-index: 100;
}
</style>
