<template>
  <div
    class="team-member flex flex-col w-full sm:w-1/2 lg:w-1/4 p-4 mb-4 text-center"
  >
    <img class="rounded mb-6" :src="image" />
    <a :href="twitter" class="text-xl font-black cursor-pointer">{{ name }}</a>
    <p>{{ position }}</p>
  </div>
</template>
<script>
export default {
  name: "team-member",
  props: {
    image: {
      type: String,
    },
    name: {
      type: String,
    },
    position: {
      type: String,
    },
    twitter: {
      type: String,
    },
  },
};
</script>
