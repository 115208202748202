<template>
  <div class="faq wrapper flex flex-col">
    <div class="faq-wrapper z-10 relative">
      <img class="bit-3 hidden lg:flex" src="@/assets/images/bit-3.png" />
      <div class="title text-center">{{ $t("faq.title") }}</div>
      <p class="text-center lg:px-40">
        {{ $t("faq.text") }}
      </p>
      <div class="questions lg:px-40 pt-4" data-aos="fade-up">
        <faq-question
          :title="$t('faq.questions.question_1.title')"
          data-aos="fade-up"
        >
          {{ $t("faq.questions.question_1.text") }}
        </faq-question>

        <faq-question
          :title="$t('faq.questions.question_2.title')"
          data-aos="fade-up"
        >
          {{ $t("faq.questions.question_2.text") }}
        </faq-question>

        <faq-question
          :title="$t('faq.questions.question_5.title')"
          data-aos="fade-up"
        >
          {{ $t("faq.questions.question_5.text") }}
        </faq-question>
      </div>
    </div>
  </div>
</template>
<script>
import faqQuestion from "../components/faq-question.vue";
export default {
  name: "faq",
  components: { faqQuestion },
};
</script>
<style scoped>
.bit-3 {
  position: absolute;
  z-index: -1;
  top: -100px;
  left: 40px;
}
</style>
